import { HttpResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PropertiesService } from '@core/services/properties/properties.service';
import { QueriesHelperService } from '@core/services/queries/queries-helper.service';
import { TapService } from '@core/services/tap/tap.service';
import { NotificationService } from '@core/services/utils/notification.service';
import { ClipboardService } from 'ngx-clipboard';
import { MatomoTracker } from 'ngx-matomo';

export interface DialogData {
  header: string;
  body: any;
}

@Component({
  template: `
    <h1 mat-dialog-title class="query-dialog-header">
      {{ data.header }}

      <button
        class="me-2"
        color="basic"
        mat-raised-button
        (click)="copyString(data.body.toString())"
        matTooltip="Copy query to clipboard"
      >
        <mat-icon fontSet="material-icons-outlined">content_copy</mat-icon>
        <span>Copy Query</span>
      </button>
      <button
        class="me-2"
        color="basic"
        mat-raised-button
        (click)="copyString(this.getCurlString())"
        matTooltip="Copy CURL to clipboard"
      >
        <mat-icon fontSet="material-icons-outlined"> content_copy </mat-icon>
        <span>Copy CURL</span>
      </button>
      <button
        class="me-2"
        color="basic"
        mat-raised-button
        (click)="copyString(this.getWgetString())"
        matTooltip="Copy WGET to clipboard"
      >
        <mat-icon fontSet="material-icons-outlined"> content_copy </mat-icon>
        <span>Copy WGET</span>
      </button>
      <button
        class="me-2"
        color="basic"
        mat-raised-button
        (click)="copyString(this.getAstroqueryString())"
        matTooltip="Copy astroquery commands to clipboard"
      >
        <mat-icon fontSet="material-icons-outlined"> content_copy </mat-icon>
        <span>Copy Astroquery</span>
      </button>

      <button
        class="me-2"
        color="basic"
        mat-raised-button
        (click)="this.getJupyterNotebook(false)"
        matTooltip="Download Jupyter Notebook"
      >
        <mat-icon fontSet="material-icons-outlined"> download </mat-icon>
        <span>Download Jupyter Notebook</span>
      </button>

      <button *ngIf="showDatalabs" class="me-2" color="basic" mat-raised-button [matMenuTriggerFor]="menu">
        <mat-icon fontSet="material-icons-outlined"> cloud_upload </mat-icon>ESA Datalabs
      </button>
      <mat-menu #menu="matMenu">
        <div class="datalabs-popup">
          <button
            class="w-100"
            color="basic"
            mat-raised-button
            (click)="this.getJupyterNotebook(true)"
            matTooltip="Open in ESA Datalabs"
          >
            <mat-icon fontSet="material-icons-outlined"> cloud_download </mat-icon>
            <span>Open in ESA Datalabs</span>
          </button>
          <mat-form-field class="w-100 datalabs-field">
            <mat-label>(Optional) Existing Datalabs ID</mat-label>
            <input class="w-100" matInput (click)="$event.stopPropagation()" type="text" [(ngModel)]="datalabsId" />
            <mat-hint
              >If you have a running Datalabs, use it here!
              <div style="font-size: 10.5px;" (click)="$event.stopPropagation()">
                E.g. https://datalabs.esa.int/datalab-viewer/<u style="color: red;">43fab15b67714881</u>
              </div></mat-hint
            >
          </mat-form-field>
        </div>
      </mat-menu>

      <button [autofocus]="false" mat-icon-button class="close-button" (click)="close()" [mat-dialog-close]="true">
        <mat-icon color="warn">close</mat-icon>
      </button>
    </h1>
    <div mat-dialog-content class="query-dialog-content">
      <strong>Query</strong><br />
      {{ data.body }}
      <br />
      <br />
      <strong>CURL</strong><br />
      {{ getCurlString() }}
      <br />
      <br />
      <strong>WGET</strong><br />
      {{ getWgetString() }}
      <br />
      <br />
      <strong>Astroquery</strong>
      <div class="query">{{ getAstroqueryString() }}</div>
    </div>
  `,
  styleUrls: ['./dialog-common.scss']
})
export class QueryDialogComponent {
  datalabsId: string;
  showDatalabs: boolean;

  constructor(
    protected ref: MatDialogRef<QueryDialogComponent>,
    private clipboardApi: ClipboardService,
    private tapService: TapService,
    private toastr: NotificationService,
    private queriesHelperService: QueriesHelperService,
    private propertiesService: PropertiesService,
    private matomoTracker: MatomoTracker,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.showDatalabs = this.propertiesService.getProperty('showDatalabs');
  }

  /***
   * Copy string to clipboard
   */
  copyString(inputString: string) {
    this.clipboardApi.copyFromContent(inputString);
    this.matomoTracker.trackEvent('eHST_Query', 'copy_command');
  }

  /**
   *
   * @returns
   */
  getCurlString() {
    const encodedQuery = encodeURIComponent(this.data.body.replace('json', 'vot'));
    const urlQuery = `${this.queriesHelperService.getBaseMetadataQueryUrl()}&QUERY=${encodedQuery}`;
    return `curl -o file.vot "${urlQuery}"`;
  }

  /**
   *
   * @returns
   */
  getWgetString() {
    const encodedQuery = encodeURIComponent(this.data.body.replace('json', 'vot'));
    const urlQuery = `${this.queriesHelperService.getBaseMetadataQueryUrl()}&QUERY=${encodedQuery}`;
    return `wget -O file.vot "${urlQuery}"`;
  }

  /**
   * Get Astroquery String
   * @returns
   */
  getAstroqueryString() {
    return `from astroquery.esa.hubble import ESAHubble
    esahubble = ESAHubble()
    query = "${this.data.body}"
    result = esahubble.query_tap(query)`;
  }

  /**
   * Create and download Jupiter NoteBook or show it in a Datalabs
   */
  getJupyterNotebook = (showInDatalabs: boolean) => {
    const query = this.getAstroqueryString();
    const httpOptions = {
      observe: 'response'
    };
    this.tapService
      .executeGenericPostQuery(
        this.queriesHelperService.getIpynbDownloadUrl(),
        {
          query: `${query}`,
          datalabs: showInDatalabs
        },
        httpOptions
      )
      .subscribe({
        next: (response: any) => {
          if (!showInDatalabs) {
            this.downloadDataFromTap(
              JSON.stringify(response.body),
              this.getFilename(response),
              'application/x-ipynb+json'
            );
            this.matomoTracker.trackEvent('eHST_Query', 'download_Jupyter_Notebook');
          } else {
            if (this.datalabsId) {
              let datalabsUrl = this.propertiesService.getProperty('datalabsFilenameUrl');
              const filename = this.getFilename(response);
              datalabsUrl = datalabsUrl.replace('datalabs_id', this.datalabsId).replace('notebook_filename', filename);
              window.open(datalabsUrl, '_blank');

              this.matomoTracker.trackEvent('eHST_Query', 'get_Jupyter_Notebook_existing_datalabs');
            } else {
              window.open(this.propertiesService.getProperty('datalabsUrl'), '_blank');
              this.matomoTracker.trackEvent('eHST_Query', 'get_Jupyter_Notebook_new_datalabs');
            }
          }
        },
        error: () => {
          this.toastr.error('Download failed, please try again later', 'Jupyter Notebook');
        }
      });
  };

  /**
   * Extracts the filename provided by the server
   * @param response
   * @returns
   */
  getFilename(response: HttpResponse<any>): string {
    return response.headers.get('Content-Disposition')?.split('filename=')[1];
  }

  /**
   * Download data from tap server, input data and type of data
   * @param data
   * @param type
   */
  downloadDataFromTap = (data: any, name: string, type: string) => {
    const blob = new Blob([data], { type: type });
    const a: any = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  close() {
    this.ref.close();
  }
}
