import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { QueriesHelperService } from '@core/services/queries/queries-helper.service';
import { TapService } from '@core/services/tap/tap.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-autocompleter',
  template: `
    <div [formGroup]="guestForm">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>{{ label }}</mat-label>
        <input
          type="text"
          [placeholder]="placeHolder"
          matInput
          [formControlName]="controlName"
          [formControl]="autoCompleteControl"
          [matAutocomplete]="autoCompleter"
        />
        <mat-autocomplete #autoCompleter="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions" [value]="option">
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  `
})
export class AutoCompleterComponent {
  // Inputs
  @Input() controlName = '';
  @Input() column = '';
  @Input() placeHolder = '';
  @Input() maxLength = 2;
  @Input() guestForm;
  @Input() label;
  // Form and filtered options
  autoCompleteControl = new FormControl();
  filteredOptions: Observable<any>;

  /**
   * Constructor
   * @param queriesHelper
   * @param tapService
   */
  constructor(
    private queriesHelper: QueriesHelperService,
    private tapService: TapService
  ) {
    // Autocomplete event
    this.autoCompleteControl.valueChanges.subscribe((value) => {
      // Set value in the parent form
      this.guestForm.get(this.controlName).setValue(value);
      // Check the string length
      if (value.length >= this.maxLength) {
        this.filteredOptions = ['Please wait...'] as any;
        const query = this.getQuery(value, this.column);
        this.tapService.executeGenericGetQuery(query).subscribe({
          next: (response: any) => {
            this.filteredOptions = response.data;
          },
          error: () => {
            this.filteredOptions = null;
          }
        });
      } else {
        this.filteredOptions = null;
      }
    });
  }

  /**
   * Get query for remote data
   */
  getQuery = (value, column) => {
    const dbColumn = 'a.' + column;
    return this.createdAjaxScwQuery(value, dbColumn);
  };

  /**
   * Builds the URL query to get the "distinct" values of a particular column.
   *
   * @param {string} value
   * @param {string} column
   * @returns {string}
   * @memberof TapService
   */
  createdAjaxScwQuery(value: string, column: string): string {
    let query = `select top 10 ${column} from ehst.archive a`;
    if (value) {
      query = query.concat(` where ${column} ilike \'%${value}%\'`);
    }
    query = query.concat(` GROUP BY ${column} ORDER BY ${column} ASC`);
    const encodedQuery = encodeURIComponent(query);
    // We request only the top 5 values
    return `${this.queriesHelper.getBaseMetadataQueryUrl()}&QUERY=${encodedQuery}`;
  }
}
