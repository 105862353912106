import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  header: string;
  body: any;
}

@Component({
  template: `
    <mat-card>
      <mat-card-title> {{ data.header }} </mat-card-title>
      <mat-card-content>{{ data.body }} </mat-card-content>
      <mat-card-actions>
        <div class="same-row">
          <button mat-raised-button color="primary" matTooltip="Confirm request" (click)="ref.close(true)">
            Confirm
          </button>

          <button mat-raised-button color="primary" matTooltip="Dismiss request" (click)="ref.close(false)">
            Cancel
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  `,
  styleUrls: ['./dialog-common.scss']
})
export class ConfirmDialogComponent {
  constructor(
    protected ref: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
}
