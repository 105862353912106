import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppStatusService } from '@core/services/app/app-status.service';
import { PropertiesService } from '@core/services/properties/properties.service';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'app-ui-skeleton',
  template: '<router-outlet></router-outlet>'
})
/**
 * Application Component
 */
export class AppComponent {
  constructor(
    private propsService: PropertiesService,
    private statusService: AppStatusService,
    private matomoTracker: MatomoTracker,
    private router: Router
  ) {
    const matomoId: number = this.propsService.getProperty('matomoId');
    const matomoUrl: string = this.propsService.getProperty('matomoUrl');
    if (matomoId !== undefined && matomoUrl !== undefined) {
      // let's only inject the analytics
      this.createMatomoScript(matomoUrl, matomoId);
    }

    this.statusService.matomoAllowedMessage.subscribe((allowed) => {
      if (allowed) {
        this.matomoTracker.setConsentGiven();
        return;
      }
      this.matomoTracker.forgetConsentGiven();
    });

    this.checkRedirectionByParameter();
  }

  /**
   *
   * @param url
   * @param id
   */
  createMatomoScript(url: string, id: number): void {
    const script: HTMLScriptElement = document.createElement('script');
    const matomoScript = `var _paq = window._paq = window._paq || [];
/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);
(function() {
  var u="${url}";
  _paq.push(['setTrackerUrl', u+'matomo.php']);
  _paq.push(['setSiteId', '${id}']);
  var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
  g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
})();`;
    script.innerHTML = matomoScript.trim();
    document.getElementsByTagName('head')[0].appendChild(document.createComment('Matomo'));
    document.getElementsByTagName('head')[0].appendChild(script);
    document.getElementsByTagName('head')[0].appendChild(document.createComment('End Matomo Code'));
  }

  /**
   *
   */
  checkRedirectionByParameter() {
    const initialUrl = window.location.href;
    if (initialUrl.includes('/#bibcode=') && !initialUrl.includes('/search')) {
      const bibcode = initialUrl.substring(initialUrl.indexOf('/#bibcode=')).replace('/#bibcode=', '');
      this.router.navigate([
        '/pages/search',
        {
          bibcode: decodeURIComponent(bibcode)
        }
      ]);
    }
    if (initialUrl.includes('/#observationid=') && !initialUrl.includes('/search')) {
      const observation_id = initialUrl
        .substring(initialUrl.indexOf('/#observationid='))
        .replace('/#observationid=', '');
      this.router.navigate([
        '/pages/search',
        {
          observation_id: observation_id
        }
      ]);
    }

    if (initialUrl.includes('/#proposal=') && !initialUrl.includes('/search')) {
      const proposal = initialUrl.substring(initialUrl.indexOf('/#proposal=')).replace('/#proposal=', '');
      this.router.navigate([
        '/pages/search',
        {
          proposal: proposal
        }
      ]);
    }
    if (initialUrl.includes('/#target=') && !initialUrl.includes('/search')) {
      const target = initialUrl.substring(initialUrl.indexOf('/#target=')).replace('/#target=', '');
      this.router.navigate([
        '/pages/search',
        {
          target: target
        }
      ]);
    }
  }
}
