import { NgModule, Optional, SkipSelf } from '@angular/core';
import { PropsServiceProvider } from '@core/services/properties/properties.service';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog.component';
import { DetailsDialogComponent } from '@shared/components/details-dialog.component';
import { ImageDialogComponent } from '@shared/components/image-dialog.component';
import { InformationDialogComponent } from '@shared/components/information-dialog.component';
import { QueryDialogComponent } from '@shared/components/query-dialog.component';
import { TableDialogComponent } from '@shared/components/table-dialog.component';
import { SharedModule } from '@shared/shared.module';
import { AngularMaterialModule } from 'app/angular-material/angular-material-module';
import { httpInterceptorProviders } from './interceptors';
import { ThemeModule } from './theme/theme.module';

@NgModule({
  declarations: [],
  imports: [SharedModule, AngularMaterialModule, ThemeModule.forRoot()],
  providers: [httpInterceptorProviders, PropsServiceProvider],
  entryComponents: [
    ConfirmDialogComponent,
    InformationDialogComponent,
    QueryDialogComponent,
    DetailsDialogComponent,
    TableDialogComponent,
    ImageDialogComponent
  ]
})

/** This module should be really singleton */
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
