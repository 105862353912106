import { DatePipe, HashLocationStrategy, LocationStrategy, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { EsdcTableModule } from 'esdc-table';
import { ClipboardModule } from 'ngx-clipboard';
import { MatomoModule } from 'ngx-matomo';
import { ToastrModule } from 'ngx-toastr';

import { ResponsiveService } from '@core/services/responsive/responsive.service';
import { EsdcSplashScreenComponent, EsdcSplashScreenModule } from 'esdc-widgets';
import { AngularMaterialModule } from './angular-material/angular-material-module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CacheRouteReuseStrategy } from './cache-route-reuse.strategy';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    NgOptimizedImage,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    CoreModule,
    FlexLayoutModule,
    MatomoModule.forRoot(),
    AngularMaterialModule,
    ClipboardModule,
    EsdcTableModule,
    EsdcSplashScreenModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: RouteReuseStrategy, useClass: CacheRouteReuseStrategy },
    ResponsiveService,
    DatePipe
  ],
  bootstrap: [EsdcSplashScreenComponent, AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer
  ) {
    const svgUrl = './assets/images/ESA_logo_simple.svg';

    // Material registry
    this._matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    this._matIconRegistry.registerFontClassAlias('brands', 'fab');
    this._matIconRegistry.registerFontClassAlias('solid', 'fas');
    this._matIconRegistry.registerFontClassAlias('regular', 'far');
    this._matIconRegistry.registerFontClassAlias('light', 'fal');
    this._matIconRegistry.registerFontClassAlias('duotone', 'fad');
    this._matIconRegistry.addSvgIcon('ESALogoSimple', this._domSanitizer.bypassSecurityTrustResourceUrl(svgUrl));
  }
}
