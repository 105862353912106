import { Injectable } from '@angular/core';
import { PropertiesService } from '@core/services/properties/properties.service';
import { QueryCriteria } from '@shared/model/search-form';
import { BehaviorSubject, Subject } from 'rxjs';

/**
 * This class manages the criteria history and the current criterion selected in the Search Component
 *
 * @export
 * @class AppStatusService
 */
@Injectable({
  providedIn: 'root'
})
export class AppStatusService {
  criteria_session_key = 'search_criteria_key';
  selected_criteria_session_key = 'selected_criteria_key';
  currentQuerycriteria: QueryCriteria;
  matomoAllowedMessage = new Subject<boolean>();
  // Banner
  bannerDismissed = false;
  feedbackSent = false;
  // ESASky Panel Toggle
  ESASkyPanel = new BehaviorSubject<boolean>(true);

  // Event with new query is added to the storage
  addedToStorageEvent = new Subject<any>();
  // Selected criteria event
  selectedCriteriaEvent = new Subject<any>();

  /**
   * Constructor
   * @param propsService
   */
  constructor(private propsService: PropertiesService) {}

  /**
   * Toggle ESASky panel
   * @param value
   */
  toggleESASky = (value) => {
    this.ESASkyPanel.next(value);
  };

  /**
   * Adds a new QueryCriteria to the sessionStorage.
   * @param criteria
   */
  addNewCriteriaToStorage = (criteria: QueryCriteria): QueryCriteria[] => {
    let parsedStoredCriteria: QueryCriteria[] = [];

    // Get current stored criteria
    const storedCriteria = sessionStorage.getItem(this.criteria_session_key);
    if (storedCriteria) {
      parsedStoredCriteria = JSON.parse(storedCriteria);
    }

    // Remove in case of the same criteria exists
    const cleanedCriteria: QueryCriteria[] = this.removeCriteriaIfExists(criteria, parsedStoredCriteria);

    // First in, first out but only in case the criteria does not exist already
    cleanedCriteria.push(criteria);
    this.setSelectedCriteria(criteria);

    // Number of stored queries
    const sessionSize: number = +this.propsService.getProperty('sessionQueriesLenght');
    /* istanbul ignore else */
    if (cleanedCriteria.length > sessionSize) {
      cleanedCriteria.splice(sessionSize - 1, cleanedCriteria.length - sessionSize);
    }

    // Store new array
    sessionStorage.setItem(this.criteria_session_key, JSON.stringify(cleanedCriteria));

    this.addedToStorageEvent.next(true);
    return this.getStoredCriteria();
  };

  /**
   * Reads and parses the criteria stored in the session Storage.
   */
  getStoredCriteria = (): QueryCriteria[] => {
    const storedCriteria = sessionStorage.getItem(this.criteria_session_key);
    return storedCriteria ? JSON.parse(storedCriteria) : [];
  };

  /**
   * Checks if the criteria is currently stored in the list
   * @param queryCriteria
   */
  isCriteriaStored = (queryCriteria: QueryCriteria): boolean => {
    const storedCriteria = sessionStorage.getItem(this.criteria_session_key);
    if (storedCriteria) {
      const parsedCriteria = JSON.parse(storedCriteria);
      const matchCriteria = parsedCriteria.filter(
        (criteria) => criteria.readableCriteria === queryCriteria.readableCriteria
      );
      return matchCriteria.length;
    }
    return false;
  };

  /**
   * Compares two query criteria to verify if they are the same
   * @param newQuery
   * @param lastQuery
   */
  areSameCriteria = (newQuery: QueryCriteria, lastQuery: QueryCriteria) => {
    return newQuery?.criteria === lastQuery?.criteria && newQuery?.readableCriteria === lastQuery?.readableCriteria;
  };

  /**
   * Updates the list of resolved targets in session storage
   * @param target
   */
  updateResolvedTargets = (target: string): void => {
    let targets = '';
    if (sessionStorage.getItem('resolvedTargets')) {
      targets = sessionStorage.getItem('resolvedTargets') + ',';
    }
    targets += target.toLowerCase();
    sessionStorage.setItem('resolvedTargets', targets);
  };

  /**
   * Stores the query criteria currently showed in the search panel.
   * @param criteria
   */
  setCurrentQueryCriteria = (criteria: QueryCriteria) => {
    this.currentQuerycriteria = criteria;
  };

  /**
   * Returns the query criteria stored as the current search criteria.
   */
  getCurrentQueryCriteria = (): QueryCriteria => {
    return this.currentQuerycriteria;
  };

  /**
   * Assings the value of queryCriteria as selected in the session storage
   * @param queryCriteria
   */
  setSelectedCriteria = (queryCriteria: QueryCriteria) => {
    sessionStorage.setItem(this.selected_criteria_session_key, JSON.stringify(queryCriteria));
    this.selectedCriteriaEvent.next(true);
  };

  /**
   * Retrieves the value of selected criteria in session storage
   */
  getSelectedCriteria = (): QueryCriteria => {
    const selectedCriteriaString = sessionStorage.getItem(this.selected_criteria_session_key);
    if (selectedCriteriaString) {
      return JSON.parse(selectedCriteriaString);
    }
  };

  /**
   * Clears the selected criteria in session storage
   */
  removeSelectedCriteria = () => {
    sessionStorage.removeItem(this.selected_criteria_session_key);
  };

  /**
   * Remove the current selected criteria and select, if possible, the
   * next value available
   */
  removeCriteriaAndSelect = () => {
    const selectedCriteria = sessionStorage.getItem(this.selected_criteria_session_key);
    const storedCriteria = this.getStoredCriteria();

    if (storedCriteria && selectedCriteria) {
      const parsedSelectedCriteria = JSON.parse(selectedCriteria);
      const criteriaInStorage = storedCriteria.filter(
        (crit) =>
          crit.criteria === parsedSelectedCriteria.criteria &&
          crit.readableCriteria === parsedSelectedCriteria.readableCriteria
      )[0];
      const criteriaIndex = storedCriteria.indexOf(criteriaInStorage);
      let nextSelectedIndex = criteriaIndex;
      if (criteriaIndex === 0) {
        nextSelectedIndex = 0;
      } else if (criteriaIndex === storedCriteria.length - 1) {
        nextSelectedIndex = storedCriteria.length - 2;
      }

      const filteredCriteria = storedCriteria.filter(
        (crit) =>
          crit.criteria !== parsedSelectedCriteria.criteria ||
          crit.readableCriteria !== parsedSelectedCriteria.readableCriteria
      );
      if (filteredCriteria.length) {
        sessionStorage.setItem(this.criteria_session_key, JSON.stringify(filteredCriteria));
        sessionStorage.setItem(this.selected_criteria_session_key, JSON.stringify(filteredCriteria[nextSelectedIndex]));
        this.addedToStorageEvent.next(true);
        return;
      }
      sessionStorage.removeItem(this.criteria_session_key);
      sessionStorage.removeItem(this.selected_criteria_session_key);
      this.addedToStorageEvent.next(true);
    }
  };

  // /////////////////// private methods  /////////////////////
  /**
   * Finds the inputCriteria in the list of criteria and remove it if exists.
   * @param inputCriteria
   * @param criteriaList
   * @returns QueryCriteria[]
   */
  private removeCriteriaIfExists = (inputCriteria: QueryCriteria, criteriaList: QueryCriteria[]): QueryCriteria[] => {
    return criteriaList.filter((item) => item.readableCriteria !== inputCriteria.readableCriteria);
  };

  /**
   * Set the banner as accepted.
   */
  setBannerDismissed = () => {
    this.bannerDismissed = true;
  };

  /**
   * Set the banner as accepted.
   */
  isBannerDismissed = (): boolean => {
    const errorBannerActive = this.propsService.getProperty('errorBannerActive');
    const infoBannerActive = this.propsService.getProperty('infoBannerActive');
    // If it is not necessary to show the banner as we have no info nor error, return true
    if (!errorBannerActive && !infoBannerActive) {
      return true;
    }

    return this.bannerDismissed;
  };

  /**
   *
   * @param allowed
   */
  setMatomoAlloved = (allowed: boolean) => {
    this.matomoAllowedMessage.next(allowed);
    sessionStorage.setItem('cookie-consent', String(allowed));
  };

  /**
   *
   * @param sent
   */
  setFeedbackSent = (sent: boolean) => {
    this.feedbackSent = sent;
    sessionStorage.setItem('feedbackSent', String(sent));
  };

  /**
   *
   * @returns
   */
  isFeedbackSent = () => {
    return this.feedbackSent;
  };
}
