import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { Logger } from '@core/services/utils/logger.service';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const log = new Logger('main.ts');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => log.error(err));
