import { Injectable } from '@angular/core';
import { Logger } from '@core/services/utils/logger.service';
import { Observable, Subject } from 'rxjs';

const log = new Logger('HttpCancelService');

/**
 * Injectable to cancel the http request
 */
@Injectable({
  providedIn: 'root'
})
export class HttpCancelService {
  private pendingHTTPRequests$ = new Subject<void>();

  // Cancel Pending HTTP calls
  public cancelPendingRequests(): void {
    log.info('[cancelPendingRequests] : Canceling the current request');
    this.pendingHTTPRequests$.next();
  }

  public onCancelPendingRequests(): Observable<void> {
    return this.pendingHTTPRequests$.asObservable();
  }
}
