import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { HstQueriesHelperService } from '@core/services/queries/hst-queries-helper.service';
import { SpectraService } from '@core/services/spectra/spectra.service';
import { ItemFlatNode, ItemNode } from '@shared/model/tree.model';
import { goToExternalLink } from '@utils/utils';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'app-search-tree',
  templateUrl: './search-tree.component.html',
  styleUrls: ['./search-tree.component.scss']
})
export class SearchTreeComponent implements OnChanges {
  @Input() data: any;
  @Input() label: string;
  @Input() parentFilterLabel: string;
  @Input() childFilterLabel: string;
  @Input() expandPanel = false;
  @Input() isObservationChecked = false;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<ItemFlatNode, ItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<ItemNode, ItemFlatNode>();

  /** A selected parent node to be inserted */
  // selectedParent: ItemFlatNode | null = null

  /**
   * Getter node properties
   **/
  getLevel = (node: ItemFlatNode) => node.level;
  isExpandable = (node: ItemFlatNode) => node.expandable;
  getChildren = (node: ItemNode): ItemNode[] => node.children;
  hasChild = (_: number, _nodeData: ItemFlatNode) => _nodeData.expandable;
  hasNoContent = (_: number, _nodeData: ItemFlatNode) => _nodeData.item === '';

  /**
   *
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use
   * @param node
   * @param level
   * @returns
   */
  transformer = (node: ItemNode, level: number): ItemFlatNode => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item ? existingNode : new ItemFlatNode();
    flatNode.item = node.item; // Visible value
    flatNode.id = node.id; // internal value
    flatNode.level = level; // The level of the node
    flatNode.spectraLayout = node.spectraLayout;
    //flatNode.children = node.children
    flatNode.expandable = node.children?.length > 0;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** The new item's name */
  treeControl: FlatTreeControl<ItemFlatNode> = new FlatTreeControl<ItemFlatNode>(this.getLevel, this.isExpandable);
  treeFlattener: MatTreeFlattener<ItemNode, ItemFlatNode> = new MatTreeFlattener(
    this.transformer,
    this.getLevel,
    this.isExpandable,
    this.getChildren
  );
  dataSource: MatTreeFlatDataSource<ItemNode, ItemFlatNode> = new MatTreeFlatDataSource(
    this.treeControl,
    this.treeFlattener
  );

  /** The selection for checklist */
  checklistSelection = new SelectionModel<ItemFlatNode>(true /* multiple */);

  @Output() selectedOption: EventEmitter<any> = new EventEmitter();

  // Input Filter value
  parentSearchString = '';
  childSearchString = '';
  expandToggle = true;
  selectToggle = true;

  /**
   * Constructor
   */
  constructor(
    private hstQueriesHelper: HstQueriesHelperService,
    private spectraService: SpectraService,
    private matomoTracker: MatomoTracker
  ) {
    this.dataSource.data = [];
  }

  /**
   * When we set the new values we have to reset the internal variables.
   */
  cleanInternalVariables = (): void => {
    this.checklistSelection.clear();
    this.flatNodeMap.clear();
    this.nestedNodeMap.clear();
  };

  /**
   *  On Changes event
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    /* istanbul ignore else */
    if (changes.data && changes.data.currentValue) {
      const tmp = this.dataSource.data;

      // Clean internal variables when the data exists
      if (tmp.length) {
        this.cleanInternalVariables();
      }
      // Set the new data
      this.dataSource.data = changes.data.currentValue;
      // Reset last selection in the tree
      this.restoreSelectedNodes();
      this.expandPanel = this.dataSource.data?.length > 0;
    }
  }

  // ##########################################################
  // ########## START Search filter logic from INPUT ##########
  // ##########################################################
  /**
   * Filter Leaf Node
   * @param node
   * @returns
   */
  filterLeafNode(node: ItemFlatNode): boolean {
    if (!this.childSearchString) {
      return true;
    }
    const currentSearch = this.childSearchString?.toLowerCase();
    const isDistinct = node.item.toLowerCase().indexOf(currentSearch) === -1;
    return !isDistinct;
  }

  /**
   * Filter Parent Node
   * @param node
   * @returns
   */
  filterParentNode(node: ItemFlatNode): boolean {
    const currentSearch = this.parentSearchString?.toLowerCase();
    const childSearch = this.childSearchString?.toLowerCase();

    const isEqual = node.item.toLowerCase().indexOf(currentSearch) !== -1;

    if ((!this.parentSearchString || isEqual) && !childSearch) {
      return true;
    }
    if (childSearch && isEqual) {
      const descendants = this.treeControl.getDescendants(node);
      if (descendants.some((descendantNode) => descendantNode.item.toLowerCase().indexOf(childSearch) !== -1)) {
        return true;
      }
    }
    this.treeControl.collapse(node);
    return false;
  }
  // ##########################################################
  // ########## END Search filter logic from INPUT ############
  // ##########################################################
  /**
   * Whether all the descendants of the node are selected
   * @param node
   * @returns
   */
  descendantsAllSelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return (
      descendants.length &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      })
    );
  }

  /**
   * Whether part of the descendants are selected
   * @param node
   * @returns
   */
  descendantsPartiallySelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /**
   * Toggle the item selection. Select/deselect all the descendants node
   * @param node
   */
  itemSelectionToggle(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach((child) => this.checklistSelection.isSelected(child));

    this.checkAllParentsSelection(node);
    // Filter Data from dataBase
    this.updateSelected(node);
    this.selectedOption.emit(node);
  }

  /**
   * Get selected Tree values
   * @param tree
   * @returns
   */
  getTreeSelected = (tree: SearchTreeComponent): Array<any> => {
    if (tree) {
      // Remove the expandable values
      return tree.checklistSelection.selected.filter((item) => !item.expandable);
    }
    return [];
  };

  /**
   * Get parent selected node to check the mission id
   * @param tree
   */
  getParentSelectedNode = (tree: SearchTreeComponent) => {
    const parents = [];
    if (tree) {
      for (const item of tree.checklistSelection.selected) {
        // To avoid unnecesary loop, return if parent is more thant one mission
        // in this case the table in teh union table
        if (parents.length > 1) {
          return [];
        }
        let toAdd = item;
        //is level one, we nee get to the parent value
        if (item.level === 1) {
          toAdd = this.getParentNode(item);
        }
        // Add only if not included
        if (!parents.includes(toAdd)) {
          parents.push(toAdd);
        }
      }
    }
    return parents;
  };
  /**
   * Return if tree panels has some seected value
   * @param tree
   * @returns
   */
  isChecked = (tree: SearchTreeComponent): boolean => {
    return !!tree.checklistSelection.selected.length;
  };

  /**
   * Restore selected noder, children and parent
   * @param name
   */
  restoreSelectedNodes = () => {
    // Check by name
    for (const dataNode of this.treeControl.dataNodes) {
      if (dataNode.spectraLayout[0].isSelected()) {
        this.checklistSelection.select(dataNode);
        this.treeControl.expand(dataNode);
        this.checkAllParentsSelection(dataNode);
      }
    }
  };

  /**
   * Expand Node
   * @param node
   */
  expandNode = (node) => {
    this.treeControl.expand(node);
  };

  /**
   * Check tree node by name
   * @param name
   */
  checkNodeByName = (name: string): void => {
    // Expand panel
    // Check by name
    for (const dataNode of this.treeControl.dataNodes) {
      if (dataNode.item.toUpperCase().includes(name.toUpperCase())) {
        this.checklistSelection.select(dataNode);
        const descendants = this.treeControl.getDescendants(dataNode);
        // Check all children
        this.checklistSelection.select(...descendants);
        // Expand Node
        this.treeControl.expand(dataNode);
      }
    }
  };

  /**
   * Check node by id
   * @param id
   */
  checkNodeById = (id: string): void => {
    // Expand panel
    // Check by name
    for (const dataNode of this.treeControl.dataNodes) {
      if (dataNode.id.toString().toUpperCase().includes(id.toUpperCase())) {
        this.checklistSelection.select(dataNode);
        const descendants = this.treeControl.getDescendants(dataNode);
        // Check all children
        this.checklistSelection.select(...descendants);
        // Expand Node
        this.treeControl.expand(dataNode);
      }
    }
  };

  /**
   * Check if node is selected
   * @param node
   */
  nodeSelected(node: ItemFlatNode): boolean {
    return this.checklistSelection.isSelected(node);
  }

  /**
   * Toggle a leaf to-do item selection. Check all the parents to see if they changed
   * @param node
   */
  leafItemSelectionToggle(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    // Filter Data from dataBase
    this.updateSelected(node);
    this.selectedOption.emit(node);
  }

  /**
   * Checks all the parents when a leaf node is selected/unselected
   * @param node
   */
  checkAllParentsSelection(node: ItemFlatNode): void {
    let parent: ItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /**
   * Check root node checked state and change it accordingly
   * @param node
   */
  checkRootNodeSelection(node: ItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /**
   * Get the parent node of a node
   * @param node
   * @returns
   */
  getParentNode(node: ItemFlatNode): ItemFlatNode | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /**
   * Check or uncheck all Nodes
   * @param e
   */
  changeAllChecked(check: boolean) {
    if (check) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
    this.treeControl.dataNodes.forEach((r, index) => {
      const descendants = this.treeControl.getDescendants(this.treeControl.dataNodes[index]);
      descendants.forEach((descNode) => {
        if ((check && !descNode.spectraLayout[0].isSelected()) || (!check && descNode.spectraLayout[0].isSelected())) {
          descNode.spectraLayout[0].setSelected(check);
          this.leafItemSelectionToggle(descNode);
        }
      });
    });
  }

  /**
   * Update the selected observations in the tree node
   * @param flatNode
   */
  updateSelected(flatNode: ItemFlatNode) {
    flatNode.spectraLayout.forEach((layout) => {
      layout.setSelected(this.nodeSelected(flatNode));
    });
  }

  /**
   * Expand/collapse all nodes
   */
  toggleExpand() {
    if (this.expandToggle) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
    this.expandToggle = !this.expandToggle;
  }

  /**
   * Select/deselect all nodes
   */
  toggleSelect() {
    if (this.selectToggle) {
      this.changeAllChecked(true);
    } else {
      this.changeAllChecked(false);
    }
    this.selectToggle = !this.selectToggle;
  }

  /**
   * Download single FITS with spectra
   * @param artifactId
   */
  downloadFile(artifactId: string) {
    const url = this.hstQueriesHelper.createArtifactDownloadUrl(artifactId + '.fits');
    goToExternalLink(url);
    this.matomoTracker.trackEvent('eHST_Spectra_Viewer', 'download_spectra_fits');
  }

  /**
   * Retrieves the series name in multiple lines, to avoid long strings.
   * @param label
   * @returns
   */
  getNodeLabel(label: string): string {
    return this.spectraService.getSplittedName(label);
  }
}
