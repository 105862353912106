import packageInfo from '../../package.json';

const deploymentDomain = 'localhost';

export const environment = {
  production: true,
  local: false,
  VERSION: packageInfo.version,
  cookieConsentDomain: deploymentDomain
};
