import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableDialogService } from '@core/services/table-dialog.service';

/**
 * Data dialog Interface
 */
export interface DialogData {
  tableHeaders: any;
  title: any;
  headerButton: boolean;
  subtitle: any;
  footer: any;
  doi: any;
  publications: any;
  hyperlink: boolean;
  url: any;
  searchButton: boolean;
  tableStartIndex: any;
  proposal: string[];
}

/**
 *
 */
@Component({
  template: `
    <mat-card>
      <mat-card-title class="w-100" *ngIf="data.title">
        <div class="title-div" *ngIf="data.title">
          {{ removeLastComma(data.title) }}
          <button
            *ngIf="data.headerButton"
            mat-button
            matTooltip="Search observations associated to this proposal"
            (click)="setHeaderMessage()"
          >
            <mat-icon fontSet="material-icons-outlined">search</mat-icon>
          </button>
        </div>
        <div class="subtitle-div" *ngIf="data.subtitle">
          {{ removeLastComma(data.subtitle) }}
        </div>
        <div class="subtitle-div" *ngIf="data.footer">
          {{ removeLastComma(data.footer) }}
        </div>
        <div class="subtitle-div" *ngIf="data.doi">
          <a target="_blank" [href]="getDOIUrl()">DOI: {{ data.doi }}</a>
        </div>
        <button mat-icon-button [autofocus]="false" class="close-button close-button-card" (click)="close()">
          <mat-icon color="warn">close</mat-icon>
        </button>
      </mat-card-title>
      <mat-card-content class="d-flex fixTableHead">
        <table aria-describedby="table_dialog_desc" bordered="true" hover="true" class="table table-bordered">
          <thead>
            <tr>
              <th *ngFor="let head of data.tableHeaders" scope="col">
                {{ head }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let element of data.publications">
              <td *ngFor="let el of element | slice: data.tableStartIndex : element.length - 1; let i = index">
                <div class="scrollable" *ngIf="i === 0 && data.searchButton">
                  {{ removeLastComma(el) }}
                  <button
                    mat-button
                    matTooltip="Search observations associated to this publication"
                    (click)="setDialogMessage(element[3], element[1])"
                  >
                    <mat-icon>search</mat-icon>
                  </button>
                </div>
                <div class="scrollable" *ngIf="i !== 0 || !data.searchButton">
                  {{ removeLastComma(el) }}
                </div>
              </td>
              <td *ngIf="data.hyperlink">
                <a target="_blank" [href]="data.url + element[element.length - 1]">
                  {{ element[element.length - 1] }}
                </a>
              </td>
              <td *ngIf="!data.hyperlink">
                {{ element[element.length - 1] }}
              </td>
            </tr>
          </tbody>
        </table>
      </mat-card-content>
    </mat-card>
  `,
  styleUrls: ['./table-dialog.component.scss']
})
export class TableDialogComponent {
  constructor(
    protected ref: MatDialogRef<TableDialogComponent>,
    private tableDialogService: TableDialogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  /**
   * Set dialog header menssage
   */
  setHeaderMessage() {
    this.tableDialogService.setHeaderMessage(this.data.proposal);
  }

  /**
   * Set Dialog Message
   * @param value0
   * @param value1
   */
  setDialogMessage(value0: any, value1: any) {
    this.tableDialogService.setMessage([value0, value1]);
  }

  /**
   * Remove Last comma
   * @param text
   * @returns
   */
  removeLastComma(text: string) {
    if (text.endsWith(', ')) {
      return text.slice(0, -2);
    }
    return text;
  }

  /**
   * Get Doi Url
   * @returns
   */
  getDOIUrl() {
    return 'https://doi.org/' + this.data.doi;
  }

  /**
   * Close dialog
   */
  close() {
    this.ref.close();
  }
}
