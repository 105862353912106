import { SpectraLayout } from '@viewers/spectra/spectra-model';

/**
 * Item Node
 */
export class ItemNode {
  children: ItemNode[];
  item: string;
  id: string[];
  spectraLayout: SpectraLayout[];

  constructor(item: string, id: string[], children: ItemNode[] = null, spectraLayout: SpectraLayout[]) {
    this.children = children;
    this.item = item;
    this.id = id;
    this.spectraLayout = spectraLayout;
  }
}

/**
 * Flat category item node with expandable and level information
 */
export class ItemFlatNode {
  //children: ItemNode[]
  item: string;
  id: string[];
  level: number;
  expandable: boolean;
  spectraLayout: SpectraLayout[];
}
