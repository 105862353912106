export const TimeFormats = {
  dateFormat: 'dd-MM-yyyy',
  dateTimeFormat: 'dd-MM-yyyy HH:mm:ss',
  timeFormat: 'HH:mm:ss',
  dateFormatDataBase: 'yyyy-MM-dd',
  dateTimeFormatDataBase: 'yyyy-MM-dd HH:mm:ss.S',
  startDayHour: '00:00:00',
  endDayHour: '23:59:59'
};

export const GeneralSearchConstants = {
  defRadius: 0.05
};
