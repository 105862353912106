import { Injectable } from '@angular/core';
import { SpectraLayout, SpectraSubject } from '@viewers/spectra/spectra-model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpectraService {
  spectraFileSubject = new Subject<SpectraSubject>();
  showBySegmentSubject = new Subject<boolean>();
  plotSubject = new Subject<boolean>();
  autoscaleYAxisSubject = new Subject<boolean>();

  /**
   * Communication Sidebar->Plot, to show or hide the clicked spectra file
   * @param isSelected
   * @param spectraFile
   */
  sendChangeInSpectraFile(isSelected: boolean, spectraFile: SpectraLayout): void {
    const spectraSubject: SpectraSubject = {
      selected: isSelected,
      file: spectraFile
    };
    this.spectraFileSubject.next(spectraSubject);
  }

  /**
   * Communication Sidebar->Plot, to show the plots grouped by segment or filename
   * @param isSegmentView
   */
  sendChangeInGroupView(isSegmentView: boolean): void {
    this.showBySegmentSubject.next(isSegmentView);
  }

  /**
   * Communication Sidebar->Plot, to change between single plot (with all spectras) or multiple
   * plots grouped by segment/filename
   * @param isSinglePlot
   */
  sendChangeInPlotView(isSinglePlot: boolean): void {
    this.plotSubject.next(isSinglePlot);
  }

  /**
   * Communication Sidebar->Plot, to force autoscale of Y axis on relayout
   * @param isAutoscaleYAxis
   */
  sendChangeInAutoscaleYAxis(isAutoscaleYAxis: boolean): void {
    this.autoscaleYAxisSubject.next(isAutoscaleYAxis);
  }

  /**
   * Ensures that plotly is resized after resize events
   */
  forcePlotlyResize(): void {
    window.dispatchEvent(new Event('resize'));
  }

  /**
   * Retrieves the name of the group, depending on the grouping (segment or filename)
   * @param segmentView
   * @param spectra
   * @returns spectra ID used for the title
   */
  getGroupId(segmentView: boolean, spectra: SpectraLayout): string {
    if (segmentView) {
      return spectra.getSegment();
    }
    return spectra.getFilename();
  }

  /**
   * Creates the layout for each of the groups
   * @param name
   * @returns layout for plotly for each group
   */
  getGroupLayout(name: string) {
    return {
      autosize: true,
      hovermode: 'closest',
      title: {
        text: name,
        font: {
          size: 30,
          family: 'NotesEsaBold'
        }
      },
      xaxis: {
        title: { text: 'Wavelength (Å)', font: { size: 20 } },
        showticklabels: true,
        tickfont: {
          size: 15
        }
      },
      yaxis: {
        automargin: true,
        title: {
          text: 'Flux (erg s<sup>-1</sup> cm<sup>-2</sup> Å<sup>-1</sup>)',
          standoff: 10,
          font: { size: 20 }
        },
        tickformat: '.3e',
        showticklabels: true,
        tickfont: {
          size: 15
        },
        linewidth: 1
      },
      legend: {
        x: 1,
        y: 0.5,
        title: {
          font: { size: 0 },
          text: '<i style="float:right">Click to show/hide</i>'
        }
      },
      editable: true
    };
  }

  /**
   * Retrieves the appropriate layout when changing between single/multiple plots
   * @returns style of plotly window
   */
  getSpectraStyle(single: boolean, numberOfGroups?: number): any {
    if (single) {
      return { position: 'relative', width: '100%', height: '100%' };
    }
    const plotHeight = Math.max(40, 100.0 / numberOfGroups);
    return {
      position: 'relative',
      width: '100%',
      height: 'calc(' + String(plotHeight) + '% - 5px)'
    };
  }

  /**
   * Retrieves the name with break lines to avoid long strings in the legend.
   * @param spectraName
   * @returns
   */
  getSplittedName(spectraName: string): string {
    if (spectraName.length > 15) {
      const splitName: string[] = spectraName.split('_');
      splitName[4] = '<br>' + splitName[4];
      return splitName.join('_');
    } else {
      return spectraName;
    }
  }
}
