import { Injectable } from '@angular/core';
import packageInfo from '../../../../../package.json';

/**
 * Responsible for handling the different properties:
 * the environment and the general ones.
 */
@Injectable({
  providedIn: 'root'
})
export class PropertiesService {
  // Environment props ( to be fed in the service provider based on the environment)
  envProps = {};

  // General properties
  private props = {
    calibrationLevelQuery: '&CALIBRATIONLEVEL=',
    productTypeQuery: '&PRODUCTTYPE=',
    paramsTargetResolver: 'servlet/target-resolver?TARGET_NAME=@target@&RESOLVER_TYPE=ALL&FORMAT=json',
    itemsNumber: '1000',
    sessionQueriesLenght: '20',
    esaUrl: 'http://www.esa.int',
    ehstCosmosUrl: 'https://www.cosmos.esa.int/web/hst',
    casLoginUri: 'CasLogin?redirect=@redirect@',
    casLogoutUri: 'CasLogout?redirect=@redirect@',
    tapClientParameter: '&TAPCLIENT=ehst-' + packageInfo.version,
    dayImageUrl: [
      'https://cdn.spacetelescope.org/archives/images/screen/heic1509a.jpg',
      'https://cdn.spacetelescope.org/archives/images/screen/heic2007a.jpg',
      'https://cdn.spacetelescope.org/archives/images/screen/heic1608a.jpg',
      'https://cdn.spacetelescope.org/archives/images/screen/heic0206c.jpg'
    ]
  };

  /**
   * Return value corresponding to property key.
   *
   * @param {string} key
   * @returns
   * @memberof EnvService
   */
  getProperty = (key: string): any => {
    // Look for first in the environment props
    /* istanbul ignore else */
    if (this.envProps?.hasOwnProperty(key)) {
      return this.envProps[key];
    } else if (this.props && key in this.props) {
      return this.props[key];
    }
    return undefined;
  };
}

export const PropsServiceFactory = (): PropertiesService => {
  // Create properties environment and general services
  const propsHandler = new PropertiesService();

  // Read environment variables from browser window
  const browserWindowEnv = window['__env'] || {};

  // Assign environment variables from browser window to env
  // In the current implementation, properties from env.js overwrite defaults from the EnvService.
  // If needed, a deep merge can be performed here to merge properties instead of overwriting them.
  for (const key in browserWindowEnv) {
    /* istanbul ignore else */
    if (browserWindowEnv.hasOwnProperty(key)) {
      propsHandler.envProps[key] = window['__env'][key];
    }
  }

  return propsHandler;
};

export const PropsServiceProvider = {
  provide: PropertiesService,
  useFactory: PropsServiceFactory,
  deps: []
};
