import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Logger } from '@core/services/utils/logger.service';
import { throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

const log = new Logger('NoopInterceptor');

// Requests that do not expect JSON requests
const plainRequests = ['/logout', 'WhoAmI', 'LoginStatus'];

/** Pass untouched request through to the next request handler. */
@Injectable()
export class NoopInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const started = Date.now();
    let ok: string;

    // In the login request we MUST set this content-type to have a sucessfull request!!
    req = this.formatNoJsonRequestIfProceeds(req);

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        ok = 'OK';
        // format ajax like response
        event = this.formatAjaxLikeResponseIfProceeds(event, req);
        return event;
      }),
      // Intercept responses
      catchError((response) => {
        log.info(response);
        ok = `Error: ${response.status}`;
        return throwError(() => response);
      }),
      finalize(() => {
        const elapsed = Date.now() - started;
        const msg = `${req.method} "${req.urlWithParams}"
                   ${ok} in ${elapsed} ms.`;
        log.info('Interceptor http query: ' + msg);
      })
    );
  }

  /**
   * Very important to make it work any request that returns no json objects, angular does not like it!
   *
   * @memberof NoopInterceptor
   */
  formatNoJsonRequestIfProceeds(req: HttpRequest<any>): HttpRequest<any> {
    for (const item of plainRequests) {
      if (req.url.includes(item)) {
        req = req.clone({
          headers: req.headers.set('Content-Type', 'application/x-www-form-urlencoded'),
          responseType: 'text'
        });
      }
    }
    return req;
  }

  /**
   * We must intercept the ajax-like requests.
   *
   * @param {HttpRequest<any>} req
   * @returns {boolean}
   * @memberof NoopInterceptor
   */
  ajaxLikeRequest(req: HttpRequest<any>): boolean {
    return req.urlWithParams.includes('distinct');
  }

  /**
   * Searches the username in the request.
   *
   * @param {HttpRequest<any>} req
   * @memberof NoopInterceptor
   */
  getUsername(req: HttpRequest<any>) {
    if (req && req.body) {
      const regex = /username=([^\&]+)/;
      const resMatch = regex.exec(req.body);
      return resMatch ? resMatch[1] : 'anonymous';
    }
  }

  /**
   *  We receive in the data property an array of arrays with only one result. We must convert this format
   * into something that the completer understands adding a new property 'items' that contains an array of objects
   * with a property named 'item'.
   *
   * @param {HttpEvent<any>} event
   * @param {HttpRequest<any>} req
   * @returns {*}
   * @memberof NoopInterceptor
   */
  formatAjaxLikeResponseIfProceeds(event: HttpEvent<any>, req: HttpRequest<any>): any {
    if (event instanceof HttpResponse && this.ajaxLikeRequest(req)) {
      const clonedBody = Object.assign({}, event.body);
      const dataItems = [];
      for (const dataItem of event.body.data) {
        const dataItemObj = {
          item: dataItem[0].toString()
        };
        dataItems.push(dataItemObj);
      }
      clonedBody['items'] = dataItems;
      return event.clone({ body: clonedBody });
    }

    return event;
  }
}
