import { QueryCriteria } from '@shared/model/search-form';

/**
 * Check if browser support webp images
 */
export const isSupportWebP = (): boolean => {
  return !![].map && document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') == 0;
};

/**
 * Check if is a valid json data
 * @param data
 */
export const isValidJSON = (data: string) => {
  try {
    JSON.parse(data);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * Check if is a valid url
 * @param url
 * @returns
 */
export const isValidWebUrl = (url) => {
  const regEx =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
  return regEx.test(url);
};

/**
 *
 * @param releaseDateString
 * @returns
 */
export const isPrivateData = (releaseDateString: string) => {
  return new Date(releaseDateString + 'Z').getTime() > new Date().getTime();
};

/**
 * Open url in new tab
 * @param link
 */
export const goToExternalLink = (link: string) => {
  window.open(link, '_blank');
};

/**
 *
 * @param queryCriteria
 * @returns
 */
export const getAddWhere = (queryCriteria: QueryCriteria): boolean => {
  return !queryCriteria.criteria.includes('publication') && !queryCriteria.readableCriteria.includes('proposal is');
};

/**
 * Mutator members
 * @param value
 * @param _data
 * @param _type
 * @param _params
 * @param _component
 * @returns
 */
export const membersMutator = (value) => {
  if (String(value).includes('null')) {
    return 0;
  }
  return value;
};

/**
 * Bib Code Mutator
 * @param _value
 * @param data
 * @returns
 */
export const bibCodeMutator = (_value, data) => {
  return data['pub.bib_code'];
};

/**
 * Parse tabulator response
 * @param result
 * @returns
 */
export const mapTapResponse = (result) => {
  const columnNames = result.metadata.map((meta) => meta.name);
  const dataValues = new Array<any>();
  for (const res of result.data) {
    const itemDataObject = {};
    columnNames.forEach((column, index) => {
      itemDataObject[column] = res[index];
    });
    dataValues.push(itemDataObject);
  }
  return dataValues;
};

export const listHeaderFilterNull = (value) => {
  return !value;
};

/**
 * Get [minY, maxY] values from y array within the range [minX, maxX] of x array
 * @param x
 * @param y
 * @param minX
 * @param maxX
 * @returns
 */
export const findMinMaxInRange = (x: number[], y: number[], minX: number, maxX: number) => {
  let minInRange: number | undefined;
  let maxInRange: number | undefined;
  // Iterate through x array to find values within the specified [minX, maxX] range
  for (let i = 0; i < x.length; i++) {
    const currentX = x[i];
    const currentY = y[i];
    if (currentX >= minX && currentX <= maxX) {
      // Update found min and max values
      if (minInRange === undefined || currentY < minInRange) {
        minInRange = currentY;
      }
      if (maxInRange === undefined || currentY > maxInRange) {
        maxInRange = currentY;
      }
    }
  }
  return [minInRange, maxInRange];
};
