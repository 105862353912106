import { InjectionToken, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '@shared/components/not-found.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('@pages/pages.module').then((m) => m.PagesModule)
  },
  {
    path: 'externalRedirect',
    canActivate: [externalUrlProvider],
    // We need a component here because we cannot define the route otherwise
    component: NotFoundComponent
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' }
];

const config: ExtraOptions = {
  useHash: false,
  enableTracing: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
  providers: [
    {
      // Redirect to an external URL
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.paramMap.get('externalUrl');
        const newTab = route.paramMap.get('newTab');

        if (newTab) {
          window.open(externalUrl, '_blank');
          return;
        }
        window.location.href = externalUrl;
      }
    }
  ]
})
export class AppRoutingModule {}
