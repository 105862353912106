import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';

enum ToastPositionTypes {
  bottomCenter = 'toast-bottom-center',
  bottomRight = 'toast-bottom-right',
  bottomLeft = 'toast-bottom-left',
  topCenter = 'toast-top-center',
  topRight = 'toast-top-right',
  topLeft = 'toast-top-left'
}

@Injectable({ providedIn: 'root' })
export class NotificationService {
  /**
   * Constructor
   * @param toastrService
   */
  constructor(private toastrService: ToastrService) {}

  /**
   * Success message
   * @param message
   * @param title
   * @returns
   */
  success(message = '', title = 'Success'): ActiveToast<any> {
    return this.alert('toast-success', title, message);
  }

  /**
   * Error message
   * @param message
   * @param title
   * @returns
   */
  error(message = '', title = 'Error'): ActiveToast<any> {
    return this.alert('toast-error', title, message);
  }

  /**
   * Info message
   * @param message
   * @param title
   * @returns
   */
  info(message = '', title = 'Information'): ActiveToast<any> {
    return this.alert('toast-info', title, message);
  }

  /**
   * Warning message
   * @param message
   * @param title
   * @returns
   */
  warn(message = '', title = 'Warning'): ActiveToast<any> {
    return this.alert('toast-warning', title, message);
  }

  /**
   * Show toast
   * @param type
   * @param title
   * @param body
   * @returns
   */
  alert(type: string, title: string, body: string): ActiveToast<any> {
    // Remove all
    this.toastrService.clear();
    // Toast Config
    const config = {
      tapToDismiss: true,
      timeOut: 3000,
      positionClass: ToastPositionTypes.topRight,
      preventDuplicates: true
    };
    return this.toastrService.show(body, title, config, type);
  }

  /**
   * Image Popup
   * @param body
   * @param config
   * @returns
   */
  image(body: string, config = {}): ActiveToast<any> {
    // Remove all
    return this.toastrService.show(body, '', config, 'Information');
  }
}
