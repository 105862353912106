import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-expansion-panel-header',
  template: `
    <mat-panel-title>
      <div fxLayout="row" fxFlex fxLayoutAlign="start center">
        <strong>{{ title }}</strong>
      </div>
    </mat-panel-title>
  `,
  styleUrls: ['./app-expansion-panel.component.scss']
})

/**
 * Accordion Panel
 */
export class ExpansionPanelComponent {
  @Input() title = '';
}
