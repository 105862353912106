import { Injectable } from '@angular/core';
import { PropertiesService } from '@core/services/properties/properties.service';
import { QueriesHelperService } from '@core/services/queries/queries-helper.service';
import { TapService } from '@core/services/tap/tap.service';
import { TapQuery } from '@shared/components/tabulator/tabulator-settings';
import { RaDec } from '@shared/model/coordinates';
import { QueryCriteria } from '@shared/model/search-form';

/**
 * Helper service for eHST related queries.
 */
@Injectable({
  providedIn: 'root'
})
export class HstQueriesHelperService {
  /**
   * Constructor
   */
  constructor(
    private queriesHelper: QueriesHelperService,
    private tapService: TapService,
    private propsService: PropertiesService
  ) {}

  /**
   *Query to download science windows based on observation oid.
   *
   * @param {string} observation_id
   * @returns {string}
   * @memberof HstQueriesHelperService
   */
  createObsDownloadUrl(observation_id: string): string {
    const encodedQuery = encodeURIComponent(this.createObservationQuery(observation_id));
    const tapClient = this.propsService.getProperty('tapClientParameter');
    return `${this.queriesHelper.getBaseDataDownloadUrl()}${encodedQuery}${tapClient}`;
  }

  /**
   *Query to retrieve plane_ids to retrieve the spectra for the spectra viewer
   *
   * @param {string} plane_ids
   * @returns {string}
   * @memberof HstQueriesHelperService
   */
  createBaseSpectraUrl(plane_ids: string): string {
    const encodedParams = encodeURIComponent(plane_ids);
    const tapClient = this.propsService.getProperty('tapClientParameter');
    return `${this.queriesHelper.getBaseSpectraUrl()}${encodedParams}${tapClient}`;
  }

  /**
   *Query to download products based on observation oid.
   *
   * @param {string} observation_id
   * @returns {string}
   * @memberof HstQueriesHelperService
   */
  createProductDownloadUrl(observation_id: string): string {
    const tapClient = this.propsService.getProperty('tapClientParameter');
    return `${this.queriesHelper.getBaseProductDownloadUrl()}${observation_id}${tapClient}`;
  }

  /**
   *
   * @returns
   */
  getUrlSsoNameResolver = () => {
    return this.propsService.getProperty('urlSsoNameResolver');
  };

  /**
   *
   * @param artifact_id
   * @returns
   */
  createArtifactDownloadUrl(artifact_id: string): string {
    const tapClient = this.propsService.getProperty('tapClientParameter');
    return `${this.queriesHelper.getBaseArtifactDownloadUrl()}${artifact_id}${tapClient}`;
  }

  /**
   *Query to download products based on observation oid.
   *
   * @param {string} observation_id
   * @returns {string}
   * @memberof HstQueriesHelperService
   */
  createProductByCalLevelDownloadUrl(observation_id: string, calLevel: string): string {
    const calLevelUrl = this.propsService.getProperty('calibrationLevelQuery');
    const tapClient = this.propsService.getProperty('tapClientParameter');
    return `${this.queriesHelper.getBaseProductDownloadUrl()}${observation_id}${calLevelUrl}${calLevel}${tapClient}`;
  }

  /**
   *Query to download products based on observation oid.
   *
   * @param {string} observation_id
   * @returns {string}
   * @memberof HstQueriesHelperService
   */
  createMultipleObservationDownloadUrl(observation_ids: string[], calLevel?: string, productType?: string): string {
    const calLevelUrl = this.propsService.getProperty('calibrationLevelQuery');
    const productTypeUrl = this.propsService.getProperty('productTypeQuery');
    const calLevelQuery = calLevel ? `${calLevelUrl}${calLevel}` : '';
    const productTypeQuery = productType ? `${productTypeUrl}${productType}` : '';
    const tapClient = this.propsService.getProperty('tapClientParameter');
    return `${this.queriesHelper.getBaseProductDownloadUrl()}${observation_ids.join(
      ','
    )}${calLevelQuery}${productTypeQuery}${tapClient}`;
  }

  /**
   *Query to download multiple products based on observation oid.
   *
   * @param {string} observation_id
   * @returns {string}
   * @memberof HstQueriesHelperService
   */
  createMultipleProductDownloadUrl(observation_id: string[]): string {
    const products = observation_id.join('&OBSERVATIONID=');
    const tapClient = this.propsService.getProperty('tapClientParameter');
    return `${this.queriesHelper.getBaseProductDownloadUrl()}${products}${tapClient}`;
  }

  /**
   *Query to get the publications associated to a proposalID.
   *
   * @param {string} proposalId
   * @returns {string}
   * @memberof HstQueriesHelperService
   */
  createPublicationUrl(proposalId: string): string {
    const encodedQuery = encodeURIComponent(this.createPublicationQuery(proposalId));
    const tapClient = this.propsService.getProperty('tapClientParameter');
    return `${this.queriesHelper.getBaseDataDownloadUrl()}${encodedQuery}${tapClient}`;
  }

  /**
   *Query to get the proposal associated to a proposalID.
   *
   * @param {string} proposalId
   * @returns {string}
   * @memberof HstQueriesHelperService
   */
  createProposalUrl(proposalId: string): string {
    const encodedQuery = encodeURIComponent(this.createProposalQuery(proposalId));
    const tapClient = this.propsService.getProperty('tapClientParameter');
    return `${this.queriesHelper.getBaseDataDownloadUrl()}${encodedQuery}${tapClient}`;
  }

  /**
   *
   * @param observation_id
   * @returns
   */
  createObservationQuery(observation_id: string): string {
    return (
      "select TOP 1000 * from ehst.archive a where a.observation_id = '" +
      observation_id +
      "' order by a.observation_id"
    );
  }

  /**
   *
   * @param obsid
   * @returns
   */
  createPlaneQuery(obsid: string): string {
    return "select position_bounds from ehst.plane where obsid = '" + obsid + "'";
  }

  /**
   *
   * @param observation_id
   * @returns
   */
  createFootprintQuery(observation_id: string): string {
    const encodedQuery = encodeURIComponent(this.createPlaneQuery(observation_id));
    const tapClient = this.propsService.getProperty('tapClientParameter');
    return `${this.queriesHelper.getBaseDataDownloadUrl()}&QUERY=${encodedQuery}${tapClient}`;
  }

  /**
   *
   * @param observation_id
   * @returns
   */
  createArtifactsQuery(observation_id: string): string {
    return (
      'SELECT distinct a.file_name, p.calibration_level, p.release_date, ' +
      'p.software_version, a.archive_class, p.last_modified, a.plane_uuid FROM ehst.plane p JOIN ehst.artifact a ON (p.plane_id=a.plane_id) ' +
      "JOIN ehst.observation o ON (p.observation_uuid=o.observation_uuid) where a.observation_id = '" +
      observation_id +
      "';"
    );
  }

  /**
   *
   * @param observationIds
   * @returns
   */
  createArtifactsForMultipleObservationsQuery(observationIds: string[]): string {
    return (
      'SELECT a.file_name, p.calibration_level, p.release_date, ' +
      'p.last_modified, a.archive_class, p.software_version, p.main_science_plane, o.observation_id  ' +
      'FROM ehst.plane p JOIN ehst.artifact a ON (p.plane_id=a.plane_id) ' +
      "JOIN ehst.observation o ON (p.observation_uuid=o.observation_uuid) where a.observation_id IN ('" +
      observationIds.join("','") +
      "');"
    );
  }

  /**
   *
   * @param observation_id
   * @returns
   */
  createPublicationQuery(observation_id: string): string {
    return (
      'select pub.title, pub.authors, pub.bib_code from ' +
      'ehst.publication pub left join ehst.publication_proposal pp on pp.bib_code=pub.bib_code ' +
      "left join ehst.archive a on a.proposal_id=pp.proposal_id where a.observation_id = '" +
      observation_id +
      "' order by pub.bib_code"
    );
  }

  /**
   *
   * @param proposalid
   * @returns
   */
  createProposalQuery(proposalid: string): string {
    return (
      "select distinct a.proposal_id, a.title , a.pi_name, a.doi from ehst.archive a where a.proposal_id = '" +
      proposalid +
      "';"
    );
  }

  createLevelQuery(product: string): string {
    return "select distinct p.calibration_level from ehst.plane p where p.observation_id like '" + product + "%';";
  }

  /**
   *
   * @param observation_id
   * @returns
   */
  createSourcesQuery(observation_id: string): string {
    return "select distinct matchid, ra, dec from hcv.hcv hcv where hcv.lightcurve_i = '" + observation_id + "';";
  }

  /**
   *
   * @param source
   * @returns
   */
  createLightCurveQuery(source: string): string {
    return (
      'select hcv.filter, hcv.lightcurve_d, hcv.lightcurve_cm, hcv.lightcurve_e, hcv.lightcurve_m from hcv.hcv hcv ' +
      'where hcv.matchid = ' +
      source +
      ' order by hcv.filter, hcv.lightcurve_d;'
    );
  }

  /**
   *
   * @param childrenList
   * @param observation_id
   * @returns
   */
  createUnresolvedChildrenQuery(childrenList, observation_id): string {
    const childrenAsList = childrenList.split("','");

    childrenList = childrenAsList.filter((child) => child !== observation_id).join("','");

    let query = "select * from ehst.archive a where a.observation_id in ('" + childrenList + "')";

    // Set order
    query = query.concat('  order by a.observation_id');
    return query;
  }

  /**
   * Creates a count query to the cons_observation table.
   *
   * @param {string} criteria
   * @returns {string}
   * @memberof QueriesHelperService
   */
  createConsObsCountQuery(criteria: string): string {
    let query = 'select count(*) from ehst.archive a';
    if (criteria.includes('pub.bib_code')) {
      query =
        query +
        ' join ehst.publication_proposal pp on pp.proposal_id=a.proposal_id join ehst.publication pub on ' +
        'pub.bib_code=pp.bib_code';
    }
    if (criteria?.trim().length) {
      query = query.concat(' where ', criteria.trim());
    }
    query = this.fixQuery(query);
    return query;
  }

  /**
   *Processes the response to target resolver.
   *
   * @private
   * @param {object} response
   * @returns
   * @memberof TabSearchComponent
   */
  processTargetResolvedResponse(response: object): RaDec {
    let ra, dec;
    if (response?.hasOwnProperty('objects')) {
      const resultArray = response['objects'];
      if (resultArray.length) {
        ra = resultArray[0]['raDegrees'];
        dec = resultArray[0]['decDegrees'];
        return <RaDec>{
          ra: ra,
          dec: dec
        };
      }
      return undefined;
    }
  }

  /**
   * Creates a query to the cons_observation table.
   *
   * @param {string} criteria
   * @memberof QueriesHelperService
   */
  createConsObsQuery(criteria: string, addWhere: boolean, observationIds?: []): string {
    let query = 'select * from ehst.archive a';

    if (criteria.includes('pub.bib_code')) {
      query =
        query +
        ' join ehst.publication_proposal pp on pp.proposal_id=a.proposal_id join ehst.publication pub on ' +
        'pub.bib_code=pp.bib_code';
    }
    /* istanbul ignore else */
    if (criteria?.trim().length) {
      if (addWhere) {
        query = query.concat(' where ' + criteria.trim());
      } else {
        query = query.concat(' ' + criteria.trim());
      }
    }
    /* istanbul ignore else */
    if (observationIds?.length) {
      query = query.concat(" and a.observation_id in ('");
      query = query.concat(observationIds.join("','"));
      query = query.concat("')");
    }

    // Set order
    query = query.concat(' order by a.collection desc');
    return this.fixQuery(query);
  }

  createArtifactQuery(observation_id: string) {
    return (
      "select TOP 1 artifact_id from ehst.artifact a where a.observation_id = '" +
      observation_id +
      "' and a.main_science_artifact = 'true' order by artifact_id"
    );
  }

  /**
   * Creates a query to the cons_observation table.
   *
   * @param {string} criteria
   * @memberof QueriesHelperService
   */
  createProposalTotalQuery(criteria: string, addWhere: boolean): string {
    let selectQuery = 'select distinct a.proposal_id from ehst.archive_proposal a';

    if (criteria.includes('pub.bib_code')) {
      selectQuery =
        selectQuery +
        ' join ehst.publication_proposal pp on pp.proposal_id=a.proposal_id join ehst.publication pub on ' +
        'pub.bib_code=pp.bib_code';
    }

    if (criteria?.trim().length) {
      if (addWhere) {
        selectQuery = selectQuery.concat(' where ' + criteria.trim());
      } else {
        selectQuery = selectQuery.concat(' ' + criteria.trim());
      }
    }

    // Set order
    return (
      'select distinct cast(ap.proposal_id as INTEGER) AS proposal_id, ap.pi_name, ' +
      'ap.title, ap.no_observations, ap.no_publications, ' +
      'ap.proposal_type, dp.doi from ehst.proposal ap left join ehst.doi_proposal dp ' +
      'using(proposal_id) where ap.proposal_id in (' +
      this.fixQuery(selectQuery) +
      ')'
    );
  }

  /**
   *
   * @param proposalIds
   * @returns
   */
  createSelectedProposalQuery(proposalIds: string[]) {
    return (
      'select distinct(a.proposal_id), a.pi_name, ' +
      'a.title, a.no_observations, a.no_publications, ' +
      'a.proposal_type, dp.doi from ehst.proposal a left join ehst.doi_proposal dp ' +
      "using(proposal_id) where a.proposal_id in ('" +
      proposalIds.join("','") +
      "')"
    );
  }

  /**
   *
   * @param criteria
   * @param addWhere
   * @returns
   */
  createProposalCountQuery(criteria: string, addWhere: boolean): string {
    let selectQuery = 'select count(distinct a.proposal_id) from ehst.archive_proposal a';

    if (criteria.includes('pub.bib_code')) {
      selectQuery =
        selectQuery +
        ' join ehst.publication_proposal pp on pp.proposal_id=a.proposal_id join ehst.publication pub on ' +
        'pub.bib_code=pp.bib_code';
    }

    if (criteria?.trim().length) {
      if (addWhere) {
        selectQuery = selectQuery.concat(' where ' + criteria.trim());
      } else {
        selectQuery = selectQuery.concat(' ' + criteria.trim());
      }
    }

    // Set order
    return this.fixQuery(selectQuery);
  }

  /**
   *
   * @param criteria
   * @param addWhere
   * @returns
   */
  createPublicationTotalQuery(criteria: string, addWhere: boolean): string {
    let query = '';
    if (criteria.includes('pub.bib_code =')) {
      query =
        'select distinct pub.bib_code as "pub.bib_code", pub.title, pub.authors, pub.abstract, pub.journal, ' +
        'pub.year, pub.no_observations, pub.no_proposals from ehst.publication pub left join ' +
        'ehst.publication_proposal pp on pp.bib_code=pub.bib_code left join ehst.archive a on a.proposal_id=' +
        'pp.proposal_id';
      if (this.isValidCriteria(criteria)) {
        if (addWhere) {
          query = query.concat(' where ' + criteria.trim());
        } else {
          query = query.concat(' ' + criteria.trim());
        }
      }
      query = this.fixQuery(query);
    } else {
      let selectQuery = 'select distinct a.proposal_id from ehst.archive_proposal a';
      if (this.isValidCriteria(criteria)) {
        if (addWhere) {
          selectQuery = selectQuery.concat(' where ' + criteria.trim());
        } else {
          selectQuery = selectQuery.concat(' ' + criteria.trim());
        }
      }
      query =
        'select distinct pub.bib_code as "pub.bib_code", pub.title, pub.authors, pub.abstract, pub.journal, ' +
        'pub.year, pub.no_observations, pub.no_proposals from ehst.publication pub join ehst.publication_proposal pp ' +
        'on pp.bib_code = pub.bib_code where pp.proposal_id ' +
        'in (' +
        this.fixQuery(selectQuery) +
        ')';
    }
    return query.concat(' order by pub.bib_code');
  }

  /**
   *
   * @param publicationIds
   * @returns
   */
  createSelectedPublicationQuery(publicationIds: any[]) {
    return (
      'select distinct(pub.bib_code), pub.title, pub.authors, pub.abstract, pub.journal, ' +
      'pub.year, pub.no_observations, pub.no_proposals from ehst.publication pub where pub.bib_code ' +
      "in ('" +
      publicationIds.join("','") +
      "')"
    );
  }

  /**
   *
   * @param criteria
   * @returns
   */
  private isValidCriteria(criteria: string) {
    return criteria && criteria.trim().length;
  }

  /**
   *
   * @param criteria
   * @param addWhere
   * @returns
   */
  createPublicationCountQuery(criteria: string, addWhere: boolean): string {
    let query = '';
    if (criteria.includes('pub.bib_code =')) {
      query = 'select count(distinct pub.bib_code) from ehst.publication pub';
      if (this.isValidCriteria(criteria)) {
        if (addWhere) {
          query = query.concat(' where ' + criteria.trim());
        } else {
          query = query.concat(' ' + criteria.trim());
        }
      }
    } else {
      let selectQuery = 'select distinct a.proposal_id from ehst.archive_proposal a';
      if (this.isValidCriteria(criteria)) {
        if (addWhere) {
          selectQuery = selectQuery.concat(' where ' + criteria.trim());
        } else {
          selectQuery = selectQuery.concat(' ' + criteria.trim());
        }
      }
      query =
        'select count(distinct pub.bib_code) from ehst.publication pub join ehst.publication_proposal pp ' +
        'on pp.bib_code = pub.bib_code where pp.proposal_id ' +
        'in (' +
        this.fixQuery(selectQuery) +
        ')';
    }

    return this.fixQuery(query);
  }

  /**
   *
   * @param criteria
   * @param addWhere
   * @returns
   */
  getFullTapQuerycriteria(criteria: QueryCriteria, addWhere: boolean): string {
    const tapQuery = this.createConsObsQuery(criteria.criteria, addWhere);
    return this.tapService.getFullTapQuery(tapQuery);
  }

  // ////////////////////////////// Private methods ////////////////////////////////

  /**
   * Builds the TapQuery object.
   * @param criteria
   * @param visibleColumns
   */
  public buildTapQuery(criteria: QueryCriteria, visibleColumns: string[], addWhere: boolean): TapQuery {
    return <TapQuery>{
      queryUrl: this.createConsObsQuery(criteria.criteria, addWhere),
      countQueryUrl: this.createConsObsCountQuery(criteria.criteria),
      visibleColumns: visibleColumns,
      criteria: criteria.criteria,
      readableCriteria: '*' === criteria.readableCriteria ? 'All observations' : criteria.readableCriteria
    };
  }

  /**
   *
   * @param query
   * @returns
   */
  fixQuery(query: string): string {
    if (query.includes('where where')) {
      query = query.replace('where where', 'where');
    }

    if (query.includes('where join')) {
      query = query.replace('where join', 'join');
    }

    if (query.includes('a.bib_code')) {
      query = query.replace('a.bib_code', 'pub.bib_code');
    }
    return query;
  }

  /**
   *
   * @param json
   */
  executeFeedbackPost = (json: any) => {
    const tapUrl = this.propsService.getProperty('apiUrl');
    const feedback = this.propsService.getProperty('feedback');
    this.tapService.executeGenericPostQuery(`${tapUrl}/${feedback}`, json).subscribe({
      error: (error) => {
        console.error('Error executing the feedback request', error);
      }
    });
  };

  /**
   * Creates the plane_uuid query for the spectra viewer.
   *
   * @param {string[]} observationIds
   * @returns {string}
   * @memberof QueriesHelperService
   */
  getPlanesForSpectraQuery(observationIds: string[]): string {
    return (
      "select distinct a.plane_uuid from ehst.artifact a where a.observation_id IN ('" +
      observationIds.join("','") +
      "');"
    );
  }
}
