/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpCancelInterceptor } from './cancel-interceptor';
import { NoopInterceptor } from './noop-interceptor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: NoopInterceptor, multi: true },
  {
    multi: true,
    provide: HTTP_INTERCEPTORS,
    useClass: HttpCancelInterceptor
  }
];
