<span class="spectra-header-title">
  <div class="header-tree">{{ label }}</div>
</span>
<span class="padded-filter toolbar" style="column-gap: 20px">
  <button *ngIf="expandToggle; else collapse_button" class="w-50 button-esa narrow-button" (click)="toggleExpand()">
    Expand
  </button>
  <ng-template #collapse_button>
    <button class="w-50 button-esa narrow-button" (click)="toggleExpand()">Collapse</button>
  </ng-template>

  <button *ngIf="selectToggle; else clear_button" class="w-50 button-esa narrow-button" (click)="toggleSelect()">
    Select all
  </button>
  <ng-template #clear_button>
    <button class="w-50 button-esa narrow-button" (click)="toggleSelect()">Clear</button>
  </ng-template>
</span>
<div class="filter-div">
  <mat-form-field class="w-50 filter-no-margin padded-filter" appearance="fill">
    <mat-label>{{ parentFilterLabel }}</mat-label>
    <input matInput type="text" [(ngModel)]="parentSearchString" />
  </mat-form-field>
  <mat-form-field class="w-50 filter-no-margin padded-filter" appearance="fill">
    <mat-label>{{ childFilterLabel }}</mat-label>
    <input matInput type="text" [(ngModel)]="childSearchString" />
  </mat-form-field>
</div>
<mat-tree *ngIf="dataSource.data.length > 0" [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- Children Node -->
  <mat-tree-node
    *matTreeNodeDef="let node"
    id="{{ node.item }}"
    [style.display]="filterLeafNode(node) ? 'flex' : 'none'"
    class="tree-node-children"
    matTreeNodeToggle
    matTreeNodePadding
  >
    <mat-checkbox
      [checked]="node.spectraLayout[0].isSelected()"
      (change)="leafItemSelectionToggle(node)"
      class="flex-checkbox"
      ><span class="break-span"
        >{{ node.item }}
        <mat-icon
          *ngIf="!isObservationChecked"
          (click)="downloadFile(node.item)"
          class="download-button"
          matTooltip="Download spectra FITS"
          >download</mat-icon
        ></span
      ></mat-checkbox
    >
  </mat-tree-node>

  <!-- Parent Node -->
  <mat-tree-node
    *matTreeNodeDef="let node; when: hasChild"
    id="{{ node.item }}"
    [style.display]="filterParentNode(node) ? 'flex' : 'none'"
    class="tree-node-children"
    matTreeNodePadding
  >
    <button mat-icon-button matTreeNodeToggle>
      <mat-icon> {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }} </mat-icon>
    </button>
    <mat-checkbox
      [checked]="descendantsAllSelected(node)"
      [indeterminate]="descendantsPartiallySelected(node)"
      (change)="itemSelectionToggle(node)"
      ><span class="break-span">{{ node.item }}</span>
      <mat-icon
        *ngIf="isObservationChecked"
        (click)="downloadFile(node.item)"
        class="download-button"
        matTooltip="Download spectra FITS"
        >download</mat-icon
      ></mat-checkbox
    >
  </mat-tree-node>
</mat-tree>
