import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableDialogService {
  headerMessage = new BehaviorSubject('init_message');
  dialogMessage = new BehaviorSubject('init_message');

  /**
   *
   * @param event
   */
  setHeaderMessage(event: any): void {
    this.headerMessage.next(event);
  }

  /**
   *
   * @param event
   */
  setMessage(event: any): void {
    this.dialogMessage.next(event);
  }
}
