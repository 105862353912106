import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
  header: object;
  body: any;
}
@Component({
  template: `
    <mat-card>
      <mat-card-header> {{ data.header }} </mat-card-header>
      <mat-card-content>
        <table
          aria-describedby="details_dialog"
          bordered="true"
          hover="true"
          class="table table-bordered"
          scrollY="true"
          maxHeight="400px"
        >
          <tbody>
            <tr *ngFor="let element of data.body">
              <th scope="row">
                {{ element[0] }}
              </th>
              <td *ngFor="let el of element | slice: 1">
                {{ el }}
              </td>
            </tr>
          </tbody>
        </table>
      </mat-card-content>
    </mat-card>
  `,
  styleUrls: ['./table-dialog.component.scss']
})
export class DetailsDialogComponent {
  constructor(
    protected ref: MatDialogRef<DetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
}
