import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog.component';
import { ImageDialogComponent } from '@shared/components/image-dialog.component';
import { InformationDialogComponent } from '@shared/components/information-dialog.component';
import { AngularMaterialModule } from 'app/angular-material/angular-material-module';

import { ExpansionPanelComponent } from './components/app-expansion-panel/app-expansion-panel.component';
import { AutoCompleterComponent } from './components/autocompleter.component';
import { DetailsDialogComponent } from './components/details-dialog.component';
import { NotFoundComponent } from './components/not-found.component';
import { QueryDialogComponent } from './components/query-dialog.component';
import { SearchTreeComponent } from './components/search-tree/search-tree.component';
import { TableDialogComponent } from './components/table-dialog.component';
import { TogglePanelsComponent } from './components/toggle-panels/toggle-panels.component';
import { SafePipe } from './pipes/safe.pipe';

const modules: any[] = [
  ConfirmDialogComponent,
  InformationDialogComponent,
  QueryDialogComponent,
  ImageDialogComponent,
  DetailsDialogComponent,
  AutoCompleterComponent,
  TableDialogComponent,
  NotFoundComponent,
  ExpansionPanelComponent,
  TogglePanelsComponent,
  SearchTreeComponent,
  SafePipe
];
@NgModule({
  declarations: [...modules],
  imports: [CommonModule, FlexLayoutModule, AngularMaterialModule, FormsModule, ReactiveFormsModule],
  exports: [...modules]
})
export class SharedModule {}
