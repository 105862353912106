import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

import { BreakpointType, DeviceType, OrientationType, ScreenSizeType } from './responsive.enum';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {
  /**
   * constructor
   * @param breakpointObserver
   */
  constructor(breakpointObserver: BreakpointObserver) {
    // Detect the current size when load the aplication

    // Check the current screen Size
    this.checkScreenSize(breakpointObserver);
    //Check devive type and orientation
    this.checkDeviceTypeAndOrientation(breakpointObserver);
  }

  /**
   * Get ScreenSize
   */
  private _screenSize = ScreenSizeType.Unknown;
  public screenSize(): ScreenSizeType {
    return this._screenSize;
  }

  // Show search panel
  private _showSearchPanel = true;

  /**
   * toggle search panel
   * @param value
   */
  public toggleSearchPanel(): void {
    this._showSearchPanel = !this._showSearchPanel;
  }
  /**
   * Search show search panel value
   * @param value
   */
  public setShowSearchPanel(value: boolean): void {
    this._showSearchPanel = value;
  }
  /**
   * Get show panel value
   * @returns
   */
  public showSearchPanel(): boolean {
    return this._showSearchPanel;
  }

  // Show labels in the panel
  private _showSearchLabels = true;

  /**
   * Set show labels value
   * @param value
   */
  public setShowSearchLabels(value: boolean): void {
    this._showSearchLabels = value;
  }

  /**
   * Get show labels value
   * @returns
   */
  public showSearchLabels(): boolean {
    return this._showSearchLabels;
  }

  /**
   * Get if the screen size is medium
   */
  public screenSizeMedium(): boolean {
    return this._screenSize === ScreenSizeType.Medium;
  }

  /**
   * Get if screen is XLarge
   * @returns
   */
  public screenSizeXLarge(): boolean {
    return this._screenSize === ScreenSizeType.XLarge;
  }

  /**
   * Get if screen is Large
   * @returns
   */
  public screenSizeLarge(): boolean {
    return this._screenSize === ScreenSizeType.Large;
  }

  /**
   * Condition to show or not he labels text
   * @returns
   */
  public checkShowView = () => {
    if (this.screenSizeXLarge() || this.screenSizeLarge()) {
      return true;
    }
    return false;
  };

  /**
   * Get if the screen size is Small
   */
  public screenSizeSmall(): boolean {
    return this._screenSize === ScreenSizeType.Small;
  }
  private readonly screenSizeBreakpoints = new Map([
    [Breakpoints.XSmall, ScreenSizeType.XSmall],
    [Breakpoints.Small, ScreenSizeType.Small],
    [Breakpoints.Medium, ScreenSizeType.Medium],
    [Breakpoints.Large, ScreenSizeType.Large],
    [Breakpoints.XLarge, ScreenSizeType.XLarge]
  ]);

  /**
   * Check Screen Size Observer
   * @param breakpointObserver
   */
  private checkScreenSize(breakpointObserver: BreakpointObserver): void {
    breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((result: any) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this._screenSize = this.screenSizeBreakpoints.get(query) ?? ScreenSizeType.Unknown;
            // Show labels in more than Large Size
            this.setShowSearchLabels(this.checkShowView());

            // Set search panel
            this.setShowSearchPanel(this.screenSizeSmall());
          }
        }
      });
  }

  /**
   * Get orientationPortrait
   * @returns
   */
  public orientationPortrait(): boolean {
    return this._orientation === OrientationType.Portrait;
  }
  /**
   * Get orientationLandscape
   * @returns
   */
  public orientationLandscape(): boolean {
    return this._orientation === OrientationType.Landscape;
  }

  /**
   * Get deviceDesktop
   * @returns
   */
  public deviceDesktop(): boolean {
    return this._deviceType === DeviceType.Web;
  }
  /**
   * Get deviceTablet
   * @returns
   */
  public deviceTablet(): boolean {
    return this._deviceType === DeviceType.Tablet;
  }
  /**
   * Get deviceMobile
   * @returns
   */
  public deviceMobile(): boolean {
    return this._deviceType === DeviceType.Handset;
  }

  /**
   * Get deviceType
   * @returns
   */
  private _deviceType = DeviceType.Unknown;
  public deviceType(): DeviceType {
    return this._deviceType;
  }

  /**
   * Get orientation
   * @returns
   */
  private _orientation = OrientationType.Unknown;
  public orientation(): OrientationType {
    return this._orientation;
  }

  private readonly deviceAndOrientation = new Map([
    [Breakpoints.Tablet, BreakpointType.Tablet],
    [Breakpoints.Handset, BreakpointType.Handset],
    [Breakpoints.Web, BreakpointType.Web],
    [Breakpoints.HandsetLandscape, BreakpointType.HandsetLandscape],
    [Breakpoints.HandsetPortrait, BreakpointType.HandsetPortrait],
    [Breakpoints.TabletLandscape, BreakpointType.TabletLandscape],
    [Breakpoints.TabletPortrait, BreakpointType.TabletPortrait],
    [Breakpoints.WebLandscape, BreakpointType.WebLandscape],
    [Breakpoints.WebPortrait, BreakpointType.WebPortrait]
  ]);

  /**
   * checkDeviceTypeAndOrientation
   * @param breakpointObserver
   */
  private checkDeviceTypeAndOrientation(breakpointObserver: BreakpointObserver): void {
    breakpointObserver
      .observe([
        Breakpoints.HandsetLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.WebLandscape,
        Breakpoints.WebPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.TabletPortrait
      ])
      .subscribe((result: any) => {
        const orientationTypes = Object.keys(OrientationType).map((key) => key);

        const deviceTypes = Object.keys(DeviceType).map((key) => key);

        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            const type = this.deviceAndOrientation.get(query) ?? BreakpointType.Unknown;

            orientationTypes.forEach((element) => {
              if (type.indexOf(element) !== -1) this._orientation = element as OrientationType;
            });

            deviceTypes.forEach((element) => {
              if (type.indexOf(element) !== -1) this._deviceType = element as DeviceType;
            });
          }
        }
      });
  }

  /**
   * Returns the initial size of the search panel based on the screen size
   */
  getSearchPanelSize(): number {
    return this.showSearchPanel() ? 0 : this.screenSizeXLarge() ? 15 : 20;
  }

  /**
   * Returns the initial size of the result panel based on the screen size
   */
  getResultsPanelSize() {
    return this.showSearchPanel() ? 100 : this.screenSizeXLarge() ? 85 : 80;
  }
}
