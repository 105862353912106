import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { goToExternalLink } from '@utils/utils';

/**
 * Dialog data inteface
 */
export interface DialogData {
  header: string;
  observationid: string;
  img: string;
}

@Component({
  template: `
    <mat-card class="image-dialog-card">
      <mat-card-header class="image-dialog-card-header">
        <mat-card-title>Preview : </mat-card-title>
        <mat-card-subtitle>{{ data.header }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <img
          [alt]="data.observationid"
          class="image-dialog-card-img"
          [src]="data.img"
          layout-align="center center"
          (click)="openImage(data.img)"
        />
      </mat-card-content>
    </mat-card>
  `,
  styleUrls: ['./dialog-common.scss']
})
export class ImageDialogComponent {
  constructor(
    protected ref: MatDialogRef<ImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  /**
   * Open image in a new tab
   */
  openImage = (img) => {
    goToExternalLink(img);
  };
}
